@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url("../images/desert/desert6.jpg");
  background-repeat: no-repeat, repeat;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#cone {
  width: 0px;
  height: 10px;
  border-top: 70px solid transparent;
  border-bottom: 70px solid transparent;
  border-right: 1000px solid rgb(255, 234, 0);
  border-radius: 0%;
}